<template>
  <div class="post-section">
    <ImageText v-if="item.length" :value="item" theme="Sections" />
  </div>
</template>

<script>
import ImageText from '@kvass/template-image-text'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ImageText,
  },
}
</script>

<style lang="scss">
.post-section {
  &__content {
    padding-top: 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;

    @include respond-below('phone') {
      flex-direction: column;
    }

    &-media {
      max-height: 400px;
    }
  }
  .medium-zoom-image {
    z-index: 1000;
  }
}
</style>
