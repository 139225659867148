<template>
  <div v-if="item" class="post-subpage">
    <Hero
      v-if="$path('media.cover.length', item)"
      class="post-subpage__hero"
      :item="$path('media.cover', item)"
    />

    <section v-if="$path('content', item)" class="section section__content">
      <div class="container">
        <Content :item="TransformTag($path('content', item))" />
        <ButtonComponent
          v-if="$path('customFields.page-settings.display-lead', item)"
          class="mt-3"
          :label="$t('leadFormTitle')"
          theme="primary"
          type="button"
          @click="ScrollToAnchor('post-lead')"
        />
      </div>
    </section>

    <section v-if="additionalSections.hasItems" class=" section section__additional-sections">
      <div
          :class="[
            { container: additionalSections.size !== 'full' },
            `additional-sections--${additionalSections.size}`,
          ]"
        >
      <ImageText class="additional-sections" :value="$path('customFields.additional-sections', item)" theme="sections" />
    </div>
    </section>

    <section
      class="section section__lead section--no-pt"
      v-if="$path('customFields.page-settings.display-lead', item)"
    >
      <ScrollAnchor value="post-lead" offset="-8rem" :delay="750" />
      <div class="container">
        <Roles />
        <Lead />
      </div>
    </section>
  </div>
</template>

<script>
import Hero from '@/components/Post/Hero'
import Content from '@/components/Post/Content'
import Section from '@/components/Post/Section'
import Lead from '@/components/Project/Lead'
import Roles from '@/components/Project/Roles'
import ScrollAnchor from '@kvass/scroll-anchor'
import { ScrollToAnchor } from '@/utils'
import ImageText from '@kvass/template-image-text'
import { TransformTag } from '@/utils'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    additionalSections() {
      return {
        size: this.$path('customFields.additional-sections-settings.size', this.item) || 'medium',
        hasItems: this.$path('customFields.additional-sections.0.image', this.item),
      }
    },

  },
  methods: {
    ScrollToAnchor,
    TransformTag,
  },

  components: {
    Hero,
    Content,
    Section,
    Lead,
    Roles,
    ScrollAnchor,
    ImageText,
  },
}
</script>

<style lang="scss">
.post-subpage {
  .section {
    &--no-pb {
      padding-bottom: 0;
    }
    &--no-pt {
      padding-top: 0;
    }

    &--no-p {
      padding-top: 0;
      padding-bottom: 0;
    }
    &__content {
      .container {
        text-align: center;
      }
    }

    &__lead {
      .container {
        margin-top: 2rem;
        padding: 3rem 5rem;
        display: grid;
        gap: 1.5rem;

        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        justify-content: center;

        flex-wrap: wrap;

        @include respond-below('phone') {
          padding: 1.5rem;
          gap: 3rem;
        }
      }
    }

    &__additional-sections {
      .container {
        padding: 0;
      }
      .additional-sections {
        $textOverMargin: 11rem;
        &--full {
          $maxWidth: 700px;
          .kvass-image-text__item:not(.kvass-image-text__item--text-over) {
            .kvass-image-text__content {
              max-width: $maxWidth;
            }
          }
          .kvass-image-text__item--text-over {
            .kvass-image-text__content {
              max-width: calc(#{$maxWidth} + #{$textOverMargin});
            }
          }
        }
      }

      @include respond-below('phone') {
        .container {
          padding: 0;
        }
      }
    }
  }
}
</style>
